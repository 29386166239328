import { ProvideAccountAnalytics } from '@price-for-profit/analytics';
import { AnalyticsResolver } from 'pages/analytics';
import { Navigation, buildNavigationRoutes } from '@price-for-profit/drive-account-navigation';
import { DemoResolver, UnauthorizedPage } from '@price-for-profit/drive-app-core';
import { AuthenticationRequired, LoginLayout, LoginPage } from '@price-for-profit/auth';
import { InsightLogoWaterMark, insightLoginLogo, insightLogo, loginClientLogo } from 'logos';
import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import { ROUTES } from 'routing';
import { useService } from '@price-for-profit/service-provider';

const isProd = process.env.NODE_ENV === 'production';

export function AppRoutes() {
    const {
        appConfig: { clientId },
    } = useService();
    return useRoutes([
        {
            element: <Login />,
            children: [
                {
                    path: ROUTES.login,
                    element: (
                        <LoginPage
                            tabTitle='Acumed account Login'
                            insightLoginLogo={insightLoginLogo}
                            clientLogo={loginClientLogo}
                            loginRedirectRoute={ROUTES.analytics}
                        />
                    ),
                },
            ],
        },
        {
            element: <Private />,
            children: [
                buildNavigationRoutes({ clientId, redirectRoute: ROUTES.analytics }),
                {
                    path: ROUTES.demo,
                    element: <DemoResolver />,
                },
            ],
        },
        {
            element: <PrivateAnalytics />,
            children: [
                {
                    path: ROUTES.analytics,
                    element: <AnalyticsResolver />,
                },
            ],
        },
        {
            path: ROUTES.unauthorized,
            element: <Unauthorized />,
        },
        {
            path: '*',
            element: <Navigate to={ROUTES.login} replace />,
        },
    ]);
}

function Unauthorized() {
    return <UnauthorizedPage driveLogo={insightLoginLogo} />;
}

function Login() {
    return (
        <LoginLayout>
            <Outlet />
        </LoginLayout>
    );
}
function PrivateAnalytics() {
    return (
        <AuthenticationRequired>
            <ProvideAccountAnalytics options={{ baseUrl: process.env.PUBLIC_URL }}>
                <Navigation
                    useLocal={!isProd}
                    logos={{
                        clientLogin: loginClientLogo,
                        insightWaterMark: <InsightLogoWaterMark />,
                        insight: insightLogo,
                    }}
                >
                    <Outlet />
                </Navigation>
            </ProvideAccountAnalytics>
        </AuthenticationRequired>
    );
}

function Private() {
    return (
        <AuthenticationRequired>
            <Navigation
                useLocal={!isProd}
                logos={{
                    clientLogin: loginClientLogo,
                    insightWaterMark: <InsightLogoWaterMark />,
                    insight: insightLogo,
                }}
            >
                <Outlet />
            </Navigation>
        </AuthenticationRequired>
    );
}
